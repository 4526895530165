@import "/styles/tools/";

.mediaCard {
  &__media {
    @include aspect-ratio(15, 10);
    position: relative;
    width: 100%;
    overflow: hidden;
    border-radius: rem(4);

    @include above(md) {
      height: rem(418);
    }

    picture {
      position: absolute;
      inset: 0;
    }

    img {
      @include aspect-ratio(15, 10);
      width: 100%;
      height: auto;
    }
  }

  &__image {
    display: block;
    object-fit: cover;
  }

  &__playButton {
    @include reset-button;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    width: rem(64);
    height: rem(64);
    border-radius: 0 rem(8) 0 0;
    background-color: transparentize(token("interaction-on-general-dark"), 0.3);
    z-index: 1;
    transition: ease 0.2s;

    &:hover {
      background-color: token("interaction-on-general-dark");
    }

    @include above(md) {
      width: rem(96);
      height: rem(96);
    }
  }

  &__playIcon {
    width: rem(24);
    color: token("general-dark");

    @include above(md) {
      width: rem(40);
    }
  }

  &__videoContainer {
    position: relative;
    aspect-ratio: 16/9;
  }

  &__videoEl {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &.gatedNoVideo {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background-color: rgba(map-get($colors, "error"), .15);
      box-shadow: inset 0 0 0 rem(2) rgba(map-get($colors, "error"), .5);
      display: flex;
      justify-content: center;
      align-items: center;
      color: map-get($colors, "error");
      text-align: center;
      line-height: 1.4;
      font-style: italic;
    }
  }

  &__loading {
    display: block;
    margin: 0 auto;
    text-align: center;
  }

  &__caption {
    @include typeLabel(12);
    position: relative;
    padding-left: rem(12);
    margin-top: rem(10);
    color: token("secondary-on-general-light");

    @include above("md") {
      @include typeLabel(16);
      margin-top: rem(8);
    }

    &:before {
      display: inline;
      position: absolute;
      width: rem(2);
      background-color: token("general-divide-feature-2");
      content: "";
      left: 0;
      height: 100%;
    }

    &.lightPurple,
    &.secondaryPurple {
      &:before {
        background-color: token("general-manage");
      }
    }

    &.lightYellow,
    &.secondaryYellow {
      &:before {
        background-color: token("general-perform");
      }
    }

    &.lightPink,
    &.secondaryPink {
      &:before {
        background-color: token("general-integrate");
      }
    }

    &.lightOrange,
    &.secondaryOrange {
      &:before {
        background-color: token("general-tbc");
      }
    }

    &.lightGreen,
    &.secondaryGreen {
      &:before {
        background-color: token("general-comply");
      }
    }
  }

  &__videoLength {
    margin-right: rem(8);
    font-weight: 600;
  }

  &__lightboxModalImage {
    position: relative;
  }

  &__lightbox {
    position: relative;

    &:hover,
    &:focus-visible {
      cursor: pointer;

      &:after {
        background-color: token("interaction-on-general-dark");
      }
    }

    &:after {
      pointer-events: none;
      content: '';
      position: absolute;
      bottom: rem(16);
      right: rem(16);
      z-index: 1;
      background-color: token("general-dark");
      color: token("general-dark");
      background-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H12V5H5V19H19V12H21V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H5ZM9.7 15.7L8.3 14.3L17.6 5H14V3H21V10H19V6.4L9.7 15.7Z' fill='%23ffffff'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: center;
      background-size: rem(20);
      width: rem(30);
      height: rem(30);
      padding: rem(6);
      border-radius: rem(4);
    }

  }

}

.modal {
  &__videoContainer {

    [class*="modal__content__"] {

      padding: 0 !important;
      max-width: 90% !important;
      height: auto !important;
      max-height: 90% !important;
    }



  }
}