@import "styles/tools";

.introduction {
  @include vertical-spacing;
  border-bottom: rem(1) solid token("general-divide-light");

  &__container {
    @include container;
  }

  &__grid {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    grid-row-gap: rem(40);

    @include above(md) {
      grid-template-columns: repeat(12, minmax(0, 1fr));
      grid-template-rows: 1fr;
      grid-column-gap: rem(24);
    }
  }

  &__main {
    @include above(md) {
      grid-column: span 8;

      &.full {
        grid-column: span 12;
      }
    }
  }

  &__aside {
    @include above(md) {
      grid-column: 10 / -1;
    }
  }

  &__text {
    @include rte;
    color: token("secondary-on-general-light");

    @include typeHeadline(20);

    @include above(md) {
      @include typeHeadline(28);
    }

    &:has(h1, h2) {
      @include typeHeadline(18);

      @include above(md) {
        @include typeHeadline(24);
      }
    }

    &:has(h3) {
      @include typeHeadline(16);

      @include above(md) {
        @include typeHeadline(20);
      }
    }

    h1,
    h2,
    h3 {
      text-wrap: pretty;
      color: token("primary-on-general-light");
      margin-bottom: .75em;
    }

    h1,
    h2 {
      @include typeHeadline(24);

      @include above(md) {
        @include typeHeadline(32);
      }
    }

    h3 {
      @include typeHeadline(20, "bold");

      @include above(md) {
        @include typeHeadline(24, "bold");
      }
    }

  }

  &__ctaContainer {
    margin-top: rem(24);

    @include above(md) {
      margin-top: rem(40);
    }
  }

  &+div,
  &+section {
    border-top: 0;
  }

}