@import "/styles/tools";

.heroImageClip {
  $block: &;

  &__clipmask {
    position: absolute;
    width: 0;
    height: 0;
  }

  &__clipped {
    width: 100%;
    height: auto;
    @include aspect-ratio(708, 520);
    position: relative;
    clip-path: url(#oval-left);

    picture {
      position: absolute;
      inset: 0;
    }
    
    img {
      object-fit: cover;
    }

    @include above("sm") {
      min-height: 100%;
    }

    @include below(md) {
      &.mobileBottomOval {
        @include aspect-ratio(16, 10.75);
        clip-path: url(#oval-bottom);
      }
    }
  }

  &__file {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;

    @include above("sm") {
      min-height: 100%;
    }
  }
}
