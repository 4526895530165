@import "/styles/tools/";

.mediaGallery {
  @include vertical-spacing;
  border-bottom: rem(1) solid token("general-divide-light");

  &__container {
    @include container;
  }

  &__grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: rem(32);

    @include above("md") {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: rem(24);

      &.singleItem {
        grid-template-columns: repeat(1, minmax(0, 65%));
      }
    }
  }

  & + [class^="ComponentInsightListing_insightCardListing"] {
    margin-top: rem(0) !important;
    border-top: 0 !important;
  }

  & + [class^="ComponentMultiCardCallout_multiCardCallout"] {
    border-top: 0 !important;
  }
}
