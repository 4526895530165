@import "./reset";
@import "/styles/tools/";

html,
body {
  scroll-behavior: smooth;
}

body {
  margin: 0 auto;
  overflow-x: hidden;

  &.safari {
    -webkit-font-smoothing: antialiased;
  }
}

a {
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}

button {
  background: none;
  color: inherit;
  font-family: inherit;
  font-display: swap;
}

.no-scroll {
  overflow: hidden;
}

.skip-to-main-content-link {
  position: absolute;
  left: -9999px;
  opacity: 0;
  z-index: 999;
  background-color: black;
  padding: 1em;
  color: white;

  &:focus-visible {
    left: 50%;
    transform: translateX(-50%);
    opacity: 1;
  }
}

.modal-enter-done {
  opacity: 1 !important;
  pointer-events: auto !important;
}

html {
  &:has(.modal-enter-done) {
    overflow: hidden;

    // stop ability to scroll when modal is open
    body {
      overflow: hidden;
    }
  }
}

.modal-exit {
  opacity: 0 !important;
}

// default markdown styles
strong {
  font-weight: bold;
}

em {
  font-style: italic;
}

sub,
sup {
  position: relative;
  vertical-align: baseline;
  font-size: .75em;
  line-height: 0;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

img {
  -ms-interpolation-mode: bicubic;
  display: block;
  border: 0;
}



.allowPIPWrapper {

  &.fixed {

    .allowPIP {

      position: fixed;
      top: auto;
      right: rem(16);
      bottom: rem(16);
      left: auto;
      z-index: 9999;
      transition: all 0.3s ease-in-out;
      width: 100%;
      max-width: rem(344);
      height: auto;
      max-height: rem(226);
      overflow: hidden;
      @include aspect-ratio(344, 226);
      box-shadow: 0 rem(4) rem(12) 0 rgba(0, 0, 0, 0.55);
      border-radius: rem(4);
      background: token("general-dark");
      isolation: isolate;

      @include below("md") {
        top: rem(76);
        right: auto;
        bottom: auto;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% - rem(16));
      }

      &:before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 32px;
        left: 0;
        z-index: 2;
        background: rgba(token('general-dark'), 0.0);
        content: "";
      }

      .allowPIPVideo {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        object-fit: cover;
      }

      video {
        z-index: 1;
        max-width: 100%;
        max-height: calc(100% - 32px);
        object-fit: cover;
      }

      &:hover {

        &:before {
          transition: all 0.3s ease-in-out;
          background: rgba(token('general-dark'), 0.6);
        }

        button {
          opacity: 1;
          transition: all 0.3s ease-in-out;
        }


      }

      button {
        position: relative;
        opacity: 0;
        z-index: 3;
        transition: all 0.3s ease-in-out;
        cursor: pointer;

        &.playButton {
          display: grid;
          position: absolute;
          top: calc(50%);
          left: calc(50%);
          place-content: center;
          transform: translate(-50%, -50%);
          outline: unset;
          border: 0;
          width: rem(50);
          height: rem(50);

          svg {
            fill: token('primary-on-general-dark');
            color: token("primary-on-general-dark");
          }

          &:hover {
            svg {
              fill: token('interaction-on-general-dark');
              color: token("interaction-on-general-dark");
            }
          }
        }

        &.fullScreen {
          position: absolute;
          top: rem(8);
          left: rem(8);
          outline: unset;
          border: 0;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.75 0.75H6.83824V2.41089L3.59169 2.4146L8.35355 7.17647L7.17647 8.35355L2.4146 3.59169L2.41089 6.83824H0.75V0.75Z' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.75 17.25H6.83824V15.5891L3.59169 15.5854L8.35355 10.8235L7.17647 9.64645L2.4146 14.4083L2.41089 11.1618H0.75V17.25Z' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.25 0.75L17.25 6.83824L15.5891 6.83824L15.5854 3.59169L10.8235 8.35355L9.64645 7.17647L14.4083 2.4146L11.1618 2.41089L11.1618 0.75L17.25 0.75Z' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.25 17.25L17.25 11.1618L15.5891 11.1618L15.5854 14.4083L10.8235 9.64645L9.64645 10.8235L14.4083 15.5854L11.1618 15.5891L11.1618 17.25L17.25 17.25Z' fill='white'/%3E%3C/svg%3E");
          background-position: center;
          background-size: rem(16.5);
          background-repeat: no-repeat;
          width: rem(24);
          height: rem(24);
          font-size: 0 !important;

          &:hover {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.75 0.75H6.83824V2.41089L3.59169 2.4146L8.35355 7.17647L7.17647 8.35355L2.4146 3.59169L2.41089 6.83824H0.75V0.75Z' fill='%2300c5b7'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.75 17.25H6.83824V15.5891L3.59169 15.5854L8.35355 10.8235L7.17647 9.64645L2.4146 14.4083L2.41089 11.1618H0.75V17.25Z' fill='%2300c5b7'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.25 0.75L17.25 6.83824L15.5891 6.83824L15.5854 3.59169L10.8235 8.35355L9.64645 7.17647L14.4083 2.4146L11.1618 2.41089L11.1618 0.75L17.25 0.75Z' fill='%2300c5b7'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.25 17.25L17.25 11.1618L15.5891 11.1618L15.5854 14.4083L10.8235 9.64645L9.64645 10.8235L14.4083 15.5854L11.1618 15.5891L11.1618 17.25L17.25 17.25Z' fill='%2300c5b7'/%3E%3C/svg%3E");
          }

          @include below("md") {
            display: none;
          }
        }

        &.closeButton {
          display: flex;
          align-items: center;
          justify-self: flex-end;
          gap: rem(4);
          opacity: 1;
          margin-left: auto;
          box-shadow: none;
          border: 0;
          color: token('primary-on-general-dark');

          &:hover {
            color: token('interaction-on-general-dark');
          }
        }
      }

      .closeWrapper {
        display: flex !important;
        position: absolute;
        top: unset;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 3;
        background-color: token('general-dark');
        height: 32px;
      }
    }

  }

}