@import "/styles/tools";
@import "/styles/ui/";

.modal {
  $block: &;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  inset: 0;
  background-color: token("primary-on-general-dark");
  transition: all 0.3s ease-in-out;
  z-index: 999;
  opacity: 0;
  pointer-events: none;
  overflow: auto;

  @include above(md) {
    background-color: token("general-overlay-background");
  }

  &__close {
    @include reset-button;

    @include below(md) {
      display: flex;
      align-items: center;
      border-radius: rem(60);
      border: 1px solid token("outline-interaction-secondary");
      margin: rem(32) rem(16);
      padding: rem(12) rem(24);
    }

    @include above(md) {
      position: absolute;
      top: rem(32);
      right: rem(32);
      display: flex;
      justify-content: center;
      align-items: center;
      width: rem(48);
      height: rem(48);
      border-radius: 50%;
      background-color: token("interaction-on-general-dark");
      color: token("general-dark");
    }
  }

  &__closeIcon {
    width: rem(24);
    height: rem(24);
    color: token("general-dark");
  }

  &__closeText {
    @include typeLabel(16, bold);
    margin-left: rem(17);
    color: token("general-dark");

    @include above(md) {
      display: none;
    }
  }

  &__content {
    padding: rem(40) rem(24);
    max-width: rem(846);
    background-color: token("primary-on-general-dark");
    color: token("general-dark");

    @include below(md) {
      border-top: 2px solid token("general-divide-light");
    }

    @include above(md) {
      padding: rem(64);
      margin: rem(120) auto rem(120) auto;
      border-radius: rem(4);
    }

    &.isLarge {
      @include above(md) {
        max-width: rem(1060);
      }
    }

    &.fullContent {
      padding: rem(40) rem(24);

      @include above(md) {
        padding: 0;
      }
    }
  }

  &.lightBox {
    display: flex;
    flex-direction: column;

    @include below(md) {
      border: 0;
      background-color: token("general-overlay-background");
    }

    #{$block}__close {
      z-index: 1;
      border: 2px solid white;

      @include below(md) {
        position: fixed;
        top: rem(24);
        right: rem(24);
        z-index: 1;
        align-self: flex-end;
        margin: 0;
        padding: 8px;
        background-color: token("interaction-on-general-dark");

        span {
          display: none;
        }
      }
    }

    #{$block}__content {
      @include above(md) {
        position: relative;
        margin-top: auto;
        margin-bottom: auto;
        width: min(100%, rem(1600));
        height: min(100%, rem(900));
        min-width: rem(150);
        min-height: rem(150);
        max-width: 85vw;
        max-height: 85vh;
        background: none;

        picture {
          position: absolute;
          inset: 0;
        }

        img {
          overflow: hidden;
          border-radius: rem(4) !important;
          object-fit: contain;
          filter:
            drop-shadow(0 0 0 white) drop-shadow(3px 0 0 white) drop-shadow(-3px 0 0 white) drop-shadow(0 3px 0 white) drop-shadow(0 -3px 0 white);
        }
      }

      @include below(md) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding: 0;
        border: 0;
        background: none;

        img {
          position: relative !important;
          inset: unset !important;
          margin-top: auto;
          margin-bottom: auto;
          min-width: 95vw !important;
          max-width: 95vw !important;
          border: rem(3) solid white;
          object-fit: contain;
        }
      }


      & [class*="loading"] {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: block;
        margin: 0 auto;
        text-align: center;
        z-index: 0;
      }
    }

  }
}